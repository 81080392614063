<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Created Date"
            label-for="sent_date"
          >
            <div class="position-relative">
              <flat-pickr
                v-model="filters['created_at']"
                class="form-control"
                :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                placeholder="Search Date"
              />
              <div
                v-if="filters['created_at']"
                class="flatpickr-cancel"
                @click="clearDate('created_at')"
              >
                <feather-icon icon="XIcon" />
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Ticket Type"
            label-for="ticket_type"
          >
            <v-select
              v-model="filters['ticket_type_id']"
              :options="ticketTypes"
              :reduce="option => option.id"
              label="name"
              placeholder="Select Type"
            >
              <template
                v-slot:option="option"
                class="text-truncate"
              >
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Ticket Status"
            label-for="ticket_status"
          >
            <v-select
              v-model="filters['is_open']"
              :options="statusOptions"
              :reduce="option => option.key"
              label="name"
              placeholder="Select Status"
            >
              <template
                v-slot:option="option"
                class="text-truncate"
              >
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Apply and Clear Filters -->
      <b-row class="mt-1">
        <b-col>
          <filter-buttons
            :filter-data="filters"
            :apply-filters="applyFilters"
            :clear-filters="clearFilters"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card
      no-body
    >
      <div>
        <div
          class="mx-2 mt-2 mb-1"
        >
          <b-row class="d-sm-flex align-items-start">
            <b-col
              class="d-flex flex-wrap justify-content-end align-items-center mb-1 mb-md-0"
            >
              <ReportTableButtons
                :items="rows"
                :json_fieldz="json_fields"
                :name="`Tickets - Page ${page}.xls`"
                :bulkname="`Tickets.xls`"
                :fetch="getTicketListNoPagination"
                @refresh="onClickRefresh"
              />
            </b-col>
          </b-row>
        </div>

        <!-- table -->
        <b-overlay
          id="overlay-background"
          variant="light"
          opacity="0.30"
          blur="10px"
          rounded="sm"
        >
          <b-table
            id="ticket-table"
            striped
            hover
            responsive
            :per-page="perPage"
            :items="rows"
            :fields="fields"
            show-empty
            @sort-changed="sortChanged"
          >
            <template #empty>
              <TableDataFetching
                :rows="rows"
                :data-loading="dataLoading"
              />
            </template>
            <template #cell(id)="data">
              <div style="width: 100px;">
                <router-link
                  class="text-primary font-weight-bolder"
                  :to="{ name: 'ticket-details', params: { id: data.item.id } }"
                >
                  {{ `Ref - ${data.value}` }}
                </router-link>
              </div>
            </template>
            <template #cell(type)="data">
              {{ data.value.name }}
            </template>
            <template #cell(is_open)="data">
              <div v-if="data.value">
                <b-badge variant="light-success">
                  Yes
                </b-badge>
              </div>
              <div v-else>
                <b-badge variant="light-danger">
                  No
                </b-badge>
              </div>
            </template>
            <template #cell(created_at)="data">
              <div>
                {{ __dateTimeFormatter(data.value) }}
              </div>
            </template>
            <template #cell(last_updated)="data">
              <div>
                {{ __dateTimeFormatter(data.value) }}
              </div>
            </template>
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="12"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-can="'view-tickets'"
                  :to="{ name: 'ticket-details', params: { id: data.item.id } }"
                >
                  <feather-icon icon="EyeIcon" />
                  View
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row
              cols="12"
            >
              <b-col
                class="d-flex align-items-center justify-content-sm-start"
                md="3"
              >
                <label class="width-75">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block width-100"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                class="d-flex align-items-center justify-content-sm-end"
                md="9"
              >
                <span class="text-muted pagination-text  mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="(meta.total)? meta.total : 0"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </div>
    </b-card>
    <create-ticket-modal ref="createTicketModal" />
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BPagination,
  BRow,
  BOverlay,
  VBModal,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { avatarText } from '@core/utils/filter'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import CreateTicketModal from '@/components/tickets/CreateTicketModal.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import store from '@/store/index'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const ticketRepository = RepositoryFactory.get('ticket')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BPagination,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    vSelect,
    flatPickr,
    BDropdown,
    BDropdownItem,
    BCardActions,
    BBadge,
    CreateTicketModal,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      // Filters Start
      filters: {},
      // Filters End
      avatarText,
      total: 0,
      meta: {},
      dataLoading: false,
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      ticketTypes: [],
      statusOptions: [
        {
          key: '1',
          name: 'Open',
        },
        {
          key: '0',
          name: 'Closed',
        },
      ],
      fields: [
        {
          key: 'id',
          label: 'Ref No',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'subject',
          label: 'Subject',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'type',
          label: 'Ticket Type',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'is_open',
          label: 'Is Open',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'Created Date',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'last_updated',
          label: 'Last Updated',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
        },
      ],
      rows: [],
      json_fields: {
        Id: 'id',
        Subject: {
          field: 'subject',
          callback: value => `"${value}"`,
        },
        Type: 'type.name',
        'Is Open': 'is_open',
        Created: {
          field: 'created_at',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        Updated: {
          field: 'last_updated',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    page() {
      this.getTicketList()
    },
    perPage() {
      this.getTicketList()
    },
  },
  async mounted() {
    this.totalRows = this.rows.length
    this.getTicketTypes()
    this.getTicketList()
  },

  methods: {
    async getTicketTypes() {
      try {
        this.ticketTypes = localStorage.getItem('ticket_types') ? JSON.parse(localStorage.getItem('ticket_types')) : (await ticketRepository.getTicketTypes()).data.data
        if (!localStorage.getItem('ticket_types')) localStorage.setItem('ticket_types', JSON.stringify(this.ticketTypes))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getTicketList() {
      this.dataLoading = true
      this.rows = []
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await ticketRepository.getAllTickets(this.page, this.filterQuery, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async getTicketListNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await ticketRepository.getAllTicketsNoPagination(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async openCreateTicketModal() {
      this.$refs.createTicketModal.openModal()
    },
    // REQUIRED
    applyFilters() {
      this.getTicketList()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.getTicketList()
      })
    },
    handleChangePage(page) {
      this.page = page
      this.getTicketList()
    },
    changePage(value) {
      this.perPage = value
    },
    // REQUIRED
    sortChanged(e) {
      const key = e.sortBy
      if (key) {
        const active = e.sortDesc ? '-' : ''
        this.sort = active + key
        this.getTicketList()
      }
    },
    onClickRefresh() {
      this.getTicketList()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
@import '../@core/scss/vue/libs/vue-flatpicker.scss';
@media (max-width: 767px) {
  #ticket-table thead th {
    position: sticky;
    top: 0;
  }
}
</style>
